<template>
  <div>
    <MyTable
      :rows="rows"
      :columns="columns"
      :date-range-enabled="true"
      :date-range-field="'transaction_date'"
      :is-loading="isLoading"
    >
      <template v-slot="{ props }">
        <span v-if="props.column.field === 'reference_id'">
          <b-badge
            variant="primary"
            class="w-100 badge-padding"
          >
            {{ props.row.reference_number }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'transaction_id'">
          <b-button
            variant="primary"
            class="w-100"
            @click="handleTransactionModal(props.row.orders)"
          >
            {{ props.row.transaction_id ? props.row.transaction_id : "No entry found" }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'user'">
          <span class="d-block">{{ props.row.user.name }}</span>
          <span class="d-block">{{ props.row.user.email }}</span>
          <span class="d-block">{{ props.row.user.phone }}</span>
        </span>

        <span v-else-if="props.column.field === 'price'">
          {{ $helpers.formatTotal(props.row.price) }}
        </span>

        <span v-else-if="props.column.field === 'payment_status'">
          <b-badge
            :variant="props.row.status.color_name"
            class="badge-padding w-100"
          >
            <span class="d-block">{{ props.row.status.text }}</span>
            <small class="d-block">{{ props.row.status.description }}</small>
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'transaction_date'">
          {{ $helpers.formatDateTime(props.row.date_added) }}
        </span>

        <span v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status.value !== 'process'"
                @click="handlePaymentStatusAction(props.row.id, 'process')"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  class="mr-50"
                />
                <span>Set to In Process</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.value !== 'declined'"
                @click="handlePaymentStatusAction(props.row.id, 'declined')"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Set to Declined</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.value !== 'paid'"
                @click="handlePaymentStatusAction(props.row.id, 'paid')"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Set to Paid</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <b-modal
      ref="modal"
      title="Update payment status window"
      size="lg"
      hide-footer
    >
      <ValidationObserver
        ref="paymentStatusForm"
        v-slot="{ passes }"
      >
        <b-form-group
          label="Upload screenshot as evidence:"
          class="mb-2"
        >
          <b-overlay
            :show="isUploading"
            rounded="sm"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="image"
              name="Image"
            >
              <b-form-file
                v-model="image"
                accept="image/*"
                :state="Boolean(image)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="mb-1"
                @change="onFilePicked"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-overlay>
        </b-form-group>

        <form @submit.prevent="passes(handleSubmit)">
          <b-form-group
            label="Notes:"
            class="mb-2"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="notes"
              name="Notes"
            >
              <b-form-textarea
                id="notes"
                v-model="form.notes"
                name="Notes"
                placeholder="Enter notes..."
                rows="3"
                max-rows="6"
                class="mb-1"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group class="row justify-content-end px-2">
            <b-button
              type="submit"
              variant="danger"
              :disabled="isSubmitting"
            >
              <b-spinner
                v-if="isSubmitting"
                class="mr-50 p-0"
                small
              />
              <feather-icon
                v-else
                icon="SaveIcon"
                class="mr-50"
              />
              Submit
            </b-button>

          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal
      ref="transaction"
      title="Order Details of Transaction"
      size="xl"
      hide-footer
    >
      <MyTable
        :columns="orderColumns"
        :rows="orderRows"
        :is-loading="isLoading"
      >
        <template v-slot="{ props }">
          <span v-if="props.column.field === 'reference_id'">
            <b-badge
              variant="primary"
              class="w-100 badge-padding"
            >
              {{ props.row.reference_id }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'sender'">
            <span class="d-block"><strong>{{ props.row.sender.name }}</strong></span>
            <span class="d-block">{{ props.row.sender.complete }}</span>
          </span>

          <span v-else-if="props.column.field === 'recipient'">
            <span class="d-block"><strong>{{ props.row.recipient.name }}</strong></span>
            <span class="d-block">{{ props.row.recipient.complete }}</span>
          </span>

          <span v-else-if="props.column.field === 'shipment_type'">
            <b-badge
              :variant="props.row.shipment_type === 'Smarttruck' ? 'danger' : 'success' "
              class="badge-padding w-100"
            >
              {{ props.row.shipment_type }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="props.row.status.color_name"
              class="badge-padding w-100"
            >
              <span class="d-block">{{ props.row.status.text }}</span>
              <small class="d-block">{{ props.row.status.description }}</small>
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'unit_price'">
            <span class="d-block">{{ $helpers.formatTotal(props.row.unit_price) }}</span>
          </span>

          <span v-else-if="props.column.field === 'delivery_date'">
            <span class="d-block">{{ $helpers.formatDateTime(props.row.delivery_date) }}</span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@axios'

export default {
  name: 'B2BProPaymentStatusPage',
  components: {
    MyTable,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const columns = [
      {
        label: 'Transaction ID',
        field: 'transaction_id',
        thClass: 'align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'Reference Number',
        field: 'reference_id',
        thClass: 'align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'User Details',
        field: 'user',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Payment Type',
        field: 'payment_type',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: false,
      },
      {
        label: 'Amount',
        field: 'price',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Payment Status',
        field: 'payment_status',
        thClass: 'text-center align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Transaction Date',
        field: 'transaction_date',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: false,
      },
      {
        label: 'Actions',
        field: 'actions',
        thClass: 'text-center align-middle',
        tdClass: 'text-center align-middle',
        sortable: false,
      },
    ]

    const orderColumns = [
      {
        label: 'Order ID',
        field: 'reference_id',
        thClass: 'align-middle text-nowrap',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Sender',
        field: 'sender',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Recipient',
        field: 'recipient',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Shipment',
        field: 'shipment_type',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Status',
        field: 'status',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Quantity',
        field: 'quantity',
        thClass: 'align-middle',
        tdClass: 'text-center align-middle',
        sortable: false,
      },
      {
        label: 'Unit Price',
        field: 'unit_price',
        thClass: 'align-middle text-nowrap',
        tdClass: 'text-center align-middle',
        sortable: false,
      },
      {
        label: 'Delivery Date',
        field: 'delivery_date',
        thClass: 'align-middle text-nowrap',
        tdClass: 'align-middle text-nowrap`',
        sortable: true,
      },
    ]

    return {
      columns,
      rows: [],
      orderColumns,
      orderRows: [],
      isLoading: false,
      isSubmitting: false,
      isUploading: false,
      image: null,
      form: {
        image: null,
        notes: '',
      },
      selectedId: null,
      statusType: null,
    }
  },
  created() {
    this.fetchPaymentStatus()
  },
  methods: {
    async fetchPaymentStatus() {
      this.isLoading = true
      const response = await axios.get('payment-sessions')
      if (!response.status === 200) {
        this.isLoading = false
        this.$swal({
          title: 'Error',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        })
        return
      }

      this.rows = response.data
      this.isLoading = false
    },
    handlePaymentStatusAction(id, type) {
      this.$refs.modal.show()
      this.selectedId = id
      this.statusType = type
    },
    async handleSubmit() {
      this.isSubmitting = true
      const response = await axios.patch(`payment-sessions/${this.selectedId}`, {
        status: this.statusType,
        image: this.form.image,
        notes: this.form.notes,
      })
      if (response.status === 422) {
        this.$refs.paymentStatusForm.setErrors(response.data.errors)
        this.isSubmitting = false
        return
      }

      if (response.status !== 200 && response.status !== 422) {
        this.isSubmitting = false
        this.$swal({
          title: 'Error',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        })
        return
      }

      this.isSubmitting = false
      this.$swal({
        title: 'Update success!',
        text: 'You have successfully updated the payment status.',
        icon: 'success',
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn-success',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.$refs.modal.hide()
          this.fetchPaymentStatus()
          this.resetForm()
        }
      })
    },
    onFilePicked(event) {
      const { files } = event.target
      const reader = new FileReader()
      const formData = new FormData()
      formData.append('image', files[0])
      reader.readAsDataURL(files[0])
      reader.onload = async () => {
        this.isUploading = true
        const response = await axios.post('/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.status === 422) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.errors.image[0],
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          this.isUploading = false
          this.image = null
          return
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload success! Please add notes. ',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.form.image = response.data.file_name
        this.isUploading = false
      }
    },
    resetForm() {
      this.form = {
        image: null,
        notes: '',
      }
    },
    handleTransactionModal(orders) {
      this.$refs.transaction.show()
      this.orderRows = orders
    },
  },
}
</script>

<style lang="scss" scoped></style>
